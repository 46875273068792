import * as React from 'react'
import PropTypes from 'prop-types'

import { lazy as loadable } from '@loadable/component'

import * as ServerConfig from '../server-configuration'

const ICONS = {
  AD: loadable(() => import('./andorra')),
  AE: loadable(() => import('./united-arab-emirates')),
  AF: loadable(() => import('./afghanistan')),
  AG: loadable(() => import('./antigua-and-barbuda')),
  AI: loadable(() => import('./anguilla')),
  AL: loadable(() => import('./albania')),
  AM: loadable(() => import('./armenia')),
  AO: loadable(() => import('./angola')),
  AR: loadable(() => import('./argentina')),
  AS: loadable(() => import('./american-samoa')),
  AT: loadable(() => import('./austria')),
  AU: loadable(() => import('./australia')),
  AW: loadable(() => import('./aruba')),
  AZ: loadable(() => import('./azerbaijan')),
  BA: loadable(() => import('./bosnia-and-herzegovina')),
  BB: loadable(() => import('./barbados')),
  BD: loadable(() => import('./bangladesh')),
  BE: loadable(() => import('./belgium')),
  BF: loadable(() => import('./burkina-faso')),
  BG: loadable(() => import('./bulgaria')),
  BH: loadable(() => import('./bahrain')),
  BI: loadable(() => import('./burundi')),
  BJ: loadable(() => import('./benin')),
  BM: loadable(() => import('./bermuda')),
  BN: loadable(() => import('./brunei')),
  BO: loadable(() => import('./bolivia')),
  BQ: loadable(() => import('./bonaire')),
  BR: loadable(() => import('./brazil')),
  BS: loadable(() => import('./bahamas')),
  BT: loadable(() => import('./bhutan')),
  BW: loadable(() => import('./botswana')),
  BY: loadable(() => import('./belarus')),
  BZ: loadable(() => import('./belize')),
  CA: loadable(() => import('./canada')),
  CF: loadable(() => import('./central-african-republic')),
  CG: loadable(() => import('./republic-of-the-congo')),
  CH: loadable(() => import('./switzerland')),
  CI: loadable(() => import('./ivory-coast')),
  CK: loadable(() => import('./cook-islands')),
  CL: loadable(() => import('./chile')),
  CM: loadable(() => import('./cameroon')),
  CN: loadable(() => import('./china')),
  CO: loadable(() => import('./colombia')),
  CR: loadable(() => import('./costa-rica')),
  CU: loadable(() => import('./cuba')),
  CV: loadable(() => import('./cape-verde')),
  CW: loadable(() => import('./curacao')),
  CX: loadable(() => import('./christmas-island')),
  CY: loadable(() => import('./cyprus')),
  CZ: loadable(() => import('./czech-republic')),
  DE: loadable(() => import('./germany')),
  DJ: loadable(() => import('./djibouti')),
  DK: loadable(() => import('./denmark')),
  DM: loadable(() => import('./dominica')),
  DO: loadable(() => import('./dominican-republic')),
  DZ: loadable(() => import('./algeria')),
  EC: loadable(() => import('./ecuador')),
  EE: loadable(() => import('./estonia')),
  EG: loadable(() => import('./egypt')),
  EH: loadable(() => import('./western-sahara')),
  ER: loadable(() => import('./eritrea')),
  ES: loadable(() => import('./spain')),
  ET: loadable(() => import('./ethiopia')),
  FI: loadable(() => import('./finland')),
  FJ: loadable(() => import('./fiji')),
  FK: loadable(() => import('./falkland-islands')),
  FM: loadable(() => import('./micronesia')),
  FO: loadable(() => import('./faroe-islands')),
  FR: loadable(() => import('./france')),
  GA: loadable(() => import('./gabon')),
  GB: loadable(() => import('./united-kingdom')),
  GD: loadable(() => import('./grenada')),
  GE: loadable(() => import('./georgia')),
  GG: loadable(() => import('./guernsey')),
  GH: loadable(() => import('./ghana')),
  GI: loadable(() => import('./gibraltar')),
  GL: loadable(() => import('./greenland')),
  GM: loadable(() => import('./gambia')),
  GN: loadable(() => import('./guinea')),
  GQ: loadable(() => import('./equatorial-guinea')),
  GR: loadable(() => import('./greece')),
  GT: loadable(() => import('./guatemala')),
  GU: loadable(() => import('./guam')),
  GW: loadable(() => import('./guinea-bissau')),
  GY: loadable(() => import('./guyana')),
  HK: loadable(() => import('./hong-kong')),
  HN: loadable(() => import('./honduras')),
  HR: loadable(() => import('./croatia')),
  HT: loadable(() => import('./haiti')),
  HU: loadable(() => import('./hungary')),
  ID: loadable(() => import('./indonesia')),
  IE: loadable(() => import('./ireland')),
  IL: loadable(() => import('./israel')),
  IM: loadable(() => import('./isle-of-man')),
  IN: loadable(() => import('./india')),
  IO: loadable(() => import('./british-indian-ocean-territory')),
  IQ: loadable(() => import('./iraq')),
  IR: loadable(() => import('./iran')),
  IS: loadable(() => import('./iceland')),
  IT: loadable(() => import('./italy')),
  JE: loadable(() => import('./jersey')),
  JM: loadable(() => import('./jamaica')),
  JO: loadable(() => import('./jordan')),
  JP: loadable(() => import('./japan')),
  KE: loadable(() => import('./kenya')),
  KG: loadable(() => import('./kyrgyzstan')),
  KH: loadable(() => import('./cambodia')),
  KI: loadable(() => import('./kiribati')),
  KM: loadable(() => import('./comoros')),
  KN: loadable(() => import('./saint-kitts-and-nevis')),
  KP: loadable(() => import('./north-korea')),
  KR: loadable(() => import('./south-korea')),
  KW: loadable(() => import('./kuwait')),
  KY: loadable(() => import('./cayman-islands')),
  KZ: loadable(() => import('./kazakhstan')),
  LA: loadable(() => import('./laos')),
  LB: loadable(() => import('./lebanon')),
  LI: loadable(() => import('./liechtenstein')),
  LK: loadable(() => import('./sri-lanka')),
  LR: loadable(() => import('./liberia')),
  LS: loadable(() => import('./lesotho')),
  LT: loadable(() => import('./lithuania')),
  LU: loadable(() => import('./luxembourg')),
  LV: loadable(() => import('./latvia')),
  LY: loadable(() => import('./libya')),
  MA: loadable(() => import('./morocco')),
  MC: loadable(() => import('./monaco')),
  MD: loadable(() => import('./moldova')),
  ME: loadable(() => import('./montenegro')),
  MG: loadable(() => import('./madagascar')),
  ML: loadable(() => import('./mali')),
  MN: loadable(() => import('./mongolia')),
  MO: loadable(() => import('./macao')),
  MQ: loadable(() => import('./martinique')),
  MR: loadable(() => import('./mauritania')),
  MS: loadable(() => import('./montserrat')),
  MT: loadable(() => import('./malta')),
  MU: loadable(() => import('./mauritius')),
  MV: loadable(() => import('./maldives')),
  MW: loadable(() => import('./malawi')),
  MX: loadable(() => import('./mexico')),
  MY: loadable(() => import('./malaysia')),
  MZ: loadable(() => import('./mozambique')),
  NA: loadable(() => import('./namibia')),
  NE: loadable(() => import('./niger')),
  NF: loadable(() => import('./norfolk-island')),
  NG: loadable(() => import('./nigeria')),
  NI: loadable(() => import('./nicaragua')),
  NL: loadable(() => import('./netherlands')),
  NO: loadable(() => import('./norway')),
  NP: loadable(() => import('./nepal')),
  NR: loadable(() => import('./nauru')),
  NU: loadable(() => import('./niue')),
  NZ: loadable(() => import('./new-zealand')),
  OM: loadable(() => import('./oman')),
  PA: loadable(() => import('./panama')),
  PE: loadable(() => import('./peru')),
  PF: loadable(() => import('./french-polynesia')),
  PG: loadable(() => import('./papua-new-guinea')),
  PH: loadable(() => import('./philippines')),
  PK: loadable(() => import('./pakistan')),
  PN: loadable(() => import('./pitcairn-islands')),
  PR: loadable(() => import('./puerto-rico')),
  PS: loadable(() => import('./palestine')),
  PT: loadable(() => import('./portugal')),
  PW: loadable(() => import('./palau')),
  PY: loadable(() => import('./paraguay')),
  QA: loadable(() => import('./qatar')),
  RO: loadable(() => import('./romania')),
  RS: loadable(() => import('./serbia')),
  RU: loadable(() => import('./russia')),
  RW: loadable(() => import('./rwanda')),
  SA: loadable(() => import('./saudi-arabia')),
  SB: loadable(() => import('./solomon-islands')),
  SC: loadable(() => import('./seychelles')),
  SD: loadable(() => import('./sudan')),
  SE: loadable(() => import('./sweden')),
  SG: loadable(() => import('./singapore')),
  SI: loadable(() => import('./slovenia')),
  SK: loadable(() => import('./slovakia')),
  SL: loadable(() => import('./sierra-leone')),
  SM: loadable(() => import('./san-marino')),
  SN: loadable(() => import('./senegal')),
  SO: loadable(() => import('./somalia')),
  SR: loadable(() => import('./suriname')),
  SS: loadable(() => import('./south-sudan')),
  SX: loadable(() => import('./sint-maarten')),
  SY: loadable(() => import('./syria')),
  SZ: loadable(() => import('./swaziland')),
  TD: loadable(() => import('./chad')),
  TG: loadable(() => import('./togo')),
  TH: loadable(() => import('./thailand')),
  TJ: loadable(() => import('./tajikistan')),
  TK: loadable(() => import('./tokelau')),
  TL: loadable(() => import('./east-timor')),
  TM: loadable(() => import('./turkmenistan')),
  TN: loadable(() => import('./tunisia')),
  TO: loadable(() => import('./tonga')),
  TR: loadable(() => import('./turkey')),
  TT: loadable(() => import('./trinidad-and-tobago')),
  TV: loadable(() => import('./tuvalu')),
  TW: loadable(() => import('./taiwan')),
  TZ: loadable(() => import('./tanzania')),
  UA: loadable(() => import('./ukraine')),
  UG: loadable(() => import('./uganda')),
  UY: loadable(() => import('./uruguay')),
  UZ: loadable(() => import('./uzbekistan')),
  VA: loadable(() => import('./vatican-city')),
  VE: loadable(() => import('./venezuela')),
  VG: loadable(() => import('./british-virgin-islands')),
  VN: loadable(() => import('./vietnam')),
  VU: loadable(() => import('./vanuatu')),
  WS: loadable(() => import('./samoa')),
  XK: loadable(() => import('./kosovo')),
  YE: loadable(() => import('./yemen')),
  ZA: loadable(() => import('./south-africa')),
  ZM: loadable(() => import('./zambia')),
  ZW: loadable(() => import('./zimbabwe')),
}

const Unknown = loadable(() => import('./unknown'))
const Worldwide = loadable(() => import('./worldwide'))

export function Flag(props) {
  const code = props.code.toUpperCase()
  const Icon = ICONS[code]
  const { country } = ServerConfig.useContext()

  if (Icon == null) {
    return <Unknown code={code} />
  }

  if (!country.enabled && !props.ignoreDisabled) {
    return <Worldwide />
  }

  return <Icon />
}

Flag.propTypes = {
  code: PropTypes.string.isRequired,
  ignoreDisabled: PropTypes.bool,
}
