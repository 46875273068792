import * as React from 'react'
import * as R from 'ramda'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Utils from '../utils'
import * as ServerConfig from '../server-configuration'
import { Flag } from '../flags'

export const Language = styled.span`
  ${props =>
    css({
      paddingLeft: '0.5em',
      '&:hover, &:active': { 'text-decoration': 'underline' },
      fontWeight: props.active ? 'bold' : '',
    })}
`

export function LocationInfo() {
  const { countries, country, locale } = ServerConfig.useContext()
  const i18n = I18n.useI18n()

  if (countries.length === 0) {
    return null
  }

  // TODO: Replace hardcode w/ backend fetched languages.
  const locales = {
    DE: ['en', 'de'],
    CA: ['en', 'fr'],
    NL: ['en'],
    FR: ['en', 'fr'],
  }

  const countryLocales = locales.hasOwnProperty(country.alpha2)
    ? locales[country.alpha2]
    : ['en']

  return (
    <Common.Box display="flex" fontSize={2} p={1} textAlign="center">
      {countryLocales.map((countryLocale, index) => (
        <Common.Box display="flex" key={countryLocale} fontSize={1} pt={0}>
          <a
            href={`/${countryLocale}-${country.alpha2}/?confirm_locale=1`}
            hrefLang={countryLocale}
          >
            <Language
              data-testid={`change-location.language.${countryLocale}`}
              active={countryLocale == locale.language}
            >
              <Flag
                ignoreDisabled
                code={Utils.Strings.langToCountry(countryLocale)}
              />{' '}
              {i18n.translate(`l10n.language.${R.toLower(countryLocale)}`)}
            </Language>
          </a>
          {index + 1 != countryLocales.length && (
            <Common.Box display="flex" pl="0.5em">
              /
            </Common.Box>
          )}
        </Common.Box>
      ))}
    </Common.Box>
  )
}
